import type { SiteConfig } from "../sites.ts";

export const sightlineConfig: SiteConfig = {
  company: {
    name: "Sightline Innovation",
    tagline: "Pioneering AI Patent Solutions",
    description: "Leading patent holding company specializing in AI and ML intellectual property",
    logo: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[var(--color-primary)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v6a1 1 0 001 1h6" />
      </svg>`
    }
  },
  theme: {
    primary: '#3730a3',    // Indigo
    secondary: '#312e81',
    accent: '#4f46e5',
    background: '#f8fafc',
    text: '#1e293b',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About Sightline Innovation",
    subtitle: "Advancing AI Through Strategic IP",
    mission: {
      title: "Our Mission",
      content: "To drive innovation in AI through strategic intellectual property development and partnerships."
    },
    team: [
      {
        name: "Dr. Michael Chen",
        role: "Chief IP Officer",
        bio: "20+ years in AI/ML patent development"
      },
      {
        name: "Dr. Sarah Johnson",
        role: "Head of Licensing",
        bio: "Former IP strategist at major tech companies"
      },
      {
        name: "David Miller",
        role: "Partnership Director",
        bio: "Expert in strategic IP partnerships and licensing"
      }
    ],
    values: [
      {
        title: "Innovation",
        description: "Pushing the boundaries of AI technology"
      },
      {
        title: "Partnership",
        description: "Creating value through collaboration"
      },
      {
        title: "Protection",
        description: "Securing and leveraging intellectual property"
      }
    ]
  },
  portfolioPage: {
    title: "Patent Portfolio",
    subtitle: "Revolutionary AI & ML Patents",
    companies: [
      {
        name: "Human-in-the-Loop AI",
        description: "Advanced human-AI interaction patents",
        status: "Licensed",
        year: 2022
      },
      {
        name: "IoT Machine Learning",
        description: "Edge computing and IoT ML solutions",
        status: "Available",
        year: 2023
      },
      {
        name: "AI Integration Framework",
        description: "Enterprise AI deployment solutions",
        status: "In Development",
        year: 2024
      },
      {
        name: "Photonic AI Systems",
        description: "Advanced OCT and spectroscopy solutions",
        status: "Licensed",
        year: 2023
      }
    ],
    investmentCriteria: [
      {
        title: "Innovation Impact",
        description: "Breakthrough AI/ML technologies"
      },
      {
        title: "Market Application",
        description: "Broad commercial potential"
      },
      {
        title: "Technical Depth",
        description: "Strong technical foundations"
      }
    ]
  },
  technologyPage: {
    title: "Our IP Portfolio",
    subtitle: "Cutting-edge AI and ML Patents",
    markdown: `
# Patent Portfolio Overview

## Human-in-the-Loop AI Technology
- Advanced human-AI interaction frameworks
- Adaptive learning systems
- Real-time feedback integration
- Quality assurance protocols

## IoT Machine Learning Solutions
- Edge computing optimization
- Distributed ML systems
- Real-time data processing
- Energy-efficient algorithms

## Photonics & AI Technology
- Advanced OCT (Optical Coherence Tomography) systems
- AI-enhanced spectroscopy analysis
- Real-time image processing
- Multi-modal data fusion
- Biomedical imaging applications

## Implementation Areas

### Photonics Integration
- OCT system optimization
- Spectral analysis automation
- Medical imaging solutions
- Industrial inspection systems

### Enterprise Integration
- Seamless AI deployment
- Custom implementation frameworks
- Integration best practices
- Performance optimization

### Partnership Opportunities
- Joint development programs
- Licensing options
- Technical support
- Implementation guidance

Contact us to explore licensing and partnership opportunities.
`
  },
  solutionsPage: {
    title: "Licensing & Partnerships",
    subtitle: "Strategic IP Solutions",
    markdown: `
# IP Solutions

## Licensing Options

### Direct Licensing
- Full patent access
- Implementation support
- Technical documentation
- Ongoing updates

### Joint Development
- Collaborative projects
- Custom implementations
- Shared IP development
- Market-specific solutions

## Partnership Programs

### Strategic Alliances
- Long-term collaboration
- Joint market development
- Technical integration
- Support services

### Implementation Support
- Technical guidance
- Integration assistance
- Best practices
- Quality assurance

Contact us to discuss your IP needs and partnership opportunities.
`
  },
  homePage: {
    hero: {
      title: "Leading AI Patent Innovation",
      highlight: "Strategic IP Solutions",
      description: "Pioneering AI and ML patents with broad applications across industries."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
