import type { SiteConfig } from "../sites.ts";

export const syncSessionConfig: SiteConfig = {
  company: {
    name: "SyncSession.io",
    tagline: "Ultra-Low Latency Music Collaboration",
    description: "Private fiber network enabling real-time music collaboration across Canada with sub-10ms latency",
    logo: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[var(--color-primary)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
      </svg>`
    }
  },
  theme: {
    primary: '#ec4899',    // Pink
    secondary: '#db2777',
    accent: '#f472b6',
    background: '#0f172a',
    text: '#f8fafc',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About SyncSession.io",
    subtitle: "Revolutionizing Remote Music Collaboration",
    mission: {
      title: "Our Mission",
      content: "To break down geographical barriers for musicians through ultra-low latency networking technology."
    },
    team: [
      {
        name: "Network Operations",
        role: "Infrastructure Team",
        bio: "Dedicated fiber network specialists maintaining our sub-10ms latency network"
      },
      {
        name: "Studio Integration",
        role: "Technical Team",
        bio: "Audio engineers specializing in digital collaboration systems"
      },
      {
        name: "Music Community",
        role: "Artist Relations",
        bio: "Building connections between musicians and studios across Canada"
      }
    ],
    values: [
      {
        title: "Innovation",
        description: "Pushing the boundaries of remote collaboration"
      },
      {
        title: "Connection",
        description: "Bringing musicians together across distances"
      },
      {
        title: "Quality",
        description: "Uncompromising audio fidelity and performance"
      }
    ]
  },
  portfolioPage: {
    title: "Our Services",
    subtitle: "Professional Music Collaboration Solutions",
    companies: [
      {
        name: "Studio Sessions",
        description: "Real-time remote recording and collaboration",
        status: "Available",
        year: 2024
      },
      {
        name: "Virtual Lessons",
        description: "Professional music instruction without latency",
        status: "Available",
        year: 2024
      },
      {
        name: "Live Jamming",
        description: "Multi-location band practice and performance",
        status: "Available",
        year: 2024
      }
    ],
    investmentCriteria: [
      {
        title: "Network Coverage",
        description: "Expanding our fiber network reach"
      },
      {
        title: "Studio Integration",
        description: "Professional recording facility partnerships"
      },
      {
        title: "Community Growth",
        description: "Building the collaborative music ecosystem"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Ultra-Low Latency Network Infrastructure",
    markdown: `
# Technology Platform

## Network Infrastructure
- Private fiber-optic backbone
- Sub-10ms latency nationwide
- Dedicated music optimization
- Professional audio protocols

## Studio Integration
- Professional audio interfaces
- High-fidelity streaming
- Multi-track recording
- Real-time monitoring

## Collaboration Tools
- Virtual studio rooms
- Multi-location mixing
- Session management
- Project sharing

## Service Areas

### Remote Recording
- Multi-track capture
- Real-time monitoring
- Professional mixing
- Session archiving

### Virtual Lessons
- HD video streaming
- Perfect audio sync
- Interactive tools
- Recording capability

### Live Collaboration
- Band rehearsals
- Remote performance
- Real-time jamming
- Multi-studio sessions

Contact us to connect your studio to our network.
`
  },
  solutionsPage: {
    title: "Our Solutions",
    subtitle: "Professional Music Collaboration",
    markdown: `
# Music Solutions

## Studio Services
- Remote Recording
- Virtual Collaboration
- Live Sessions
- Project Management

## Education
- Private Lessons
- Master Classes
- Workshop Series
- Recording Techniques

## Performance
- Multi-Location Events
- Live Streaming
- Virtual Concerts
- Remote Rehearsals

## Implementation Areas

### Studio Setup
- Network Integration
- Equipment Configuration
- System Optimization
- Staff Training

### Session Management
- Booking System
- Project Tracking
- File Management
- Client Access

### Support Services
- 24/7 Technical Support
- Network Monitoring
- Quality Assurance
- Performance Analytics

Contact us to explore our music collaboration solutions.
`
  },
  homePage: {
    hero: {
      title: "Ultra-Low Latency Music Collaboration",
      highlight: "Sub-10ms Network",
      description: "Connect with musicians across Canada through our dedicated fiber network for real-time collaboration"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
