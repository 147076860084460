import type { SiteConfig } from "../sites.ts";

export const dataAssetConfig: SiteConfig = {
  company: {
    name: "DataAsset.Store",
    tagline: "Protecting and maximizing enterprise value through sovereign AI data management and compliance solutions.",
    description: "Leading provider of AI data sovereignty and management solutions. Specializing in protecting enterprise value in the AI era. Delivering secure LLM frameworks and sovereign network solutions. Enabling organizations to maintain control of their AI data assets.",
    logo: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[var(--color-primary)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4" />
      </svg>`
    }
  },
  theme: {
    primary: '#7c3aed',    // Purple
    secondary: '#6d28d9',
    accent: '#8b5cf6',
    background: '#f9fafb',
    text: '#111827',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About DataAsset.Store",
    subtitle: "Sovereign AI Data Management",
    mission: {
      title: "Our Mission",
      content: "To protect and maximize enterprise value through sovereign AI data management and compliance solutions."
    },
    team: [
      {
        name: "DaLLM Framework Team",
        role: "Product Development",
        bio: "Experts in AI data flow management and sovereign computing"
      },
      {
        name: "Compliance Team",
        role: "Governance & Analysis",
        bio: "Specialists in AI data compliance and governance"
      },
      {
        name: "Network Architecture Team",
        role: "Infrastructure",
        bio: "Experts in sovereign network design and implementation"
      }
    ],
    values: [
      {
        title: "Data Sovereignty",
        description: "Ensuring complete control over enterprise data assets"
      },
      {
        title: "Enterprise Value Protection",
        description: "Safeguarding core business value in the AI era"
      },
      {
        title: "Innovation Control",
        description: "Enabling AI adoption while maintaining data sovereignty"
      }
    ]
  },
  portfolioPage: {
    title: "Our Solutions",
    subtitle: "Enterprise Data Sovereignty",
    companies: [
      {
        name: "DaLLM Framework",
        description: "Large language model data management framework for enterprise AI applications",
        status: "Active",
        year: 2024
      },
      {
        name: "Sovereign Network Solutions",
        description: "Customized AI data sovereignty and compliance infrastructure",
        status: "Active",
        year: 2024
      }
    ],
    investmentCriteria: [
      {
        title: "Data Control",
        description: "Solutions that maintain enterprise data sovereignty"
      },
      {
        title: "Value Protection",
        description: "Safeguarding core business assets"
      },
      {
        title: "Compliance",
        description: "Meeting regulatory requirements"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Sovereign AI Infrastructure",
    markdown: `
# Technology Solutions

## DaLLM Framework
- AI data flow management
- Sovereign network infrastructure
- On-premise compute optimization
- Selective frontier model integration

## Sovereign Computing
- Government-grade compliance
- Protected customer relationships
- Secure data pathways
- Maintained business value

## Implementation Areas

### Data Sovereignty
- Complete data control
- Protected value chains
- Secure processing
- Compliance automation

### Enterprise Integration
- Custom sovereign networks
- Governance tools
- Professional services
- Secure deployment

Contact us to protect your enterprise data value.
`
  },
  homePage: {
    hero: {
      title: "Protect Your Enterprise Value",
      highlight: "Sovereign AI Data",
      description: "Maintain control of your data assets while leveraging AI innovation"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  },
  partnerships: [
    {
      company: "Sightline Innovation Inc.",
      role: "Technology licensing partner for human-in-the-loop AI patents"
    },
    {
      company: "ZeroServer.org",
      role: "Strategic partner for open-source AI infrastructure and mesh networking"
    },
    {
      company: "GlobalDevOps Inc.",
      role: "Implementation partner for global deployment and operations"
    }
  ]
};
