import type { SiteConfig } from "../sites.ts";

export const chainTokenomicsConfig: SiteConfig = {
  company: {
    name: "ChainTokenomics.IO",
    tagline: "Revolutionizing corporate finance through blockchain-based deferred revenue tokens and regulated equity trading solutions.",
    description: "Pioneering deferred revenue tokenization on Ethereum blockchain. Enabling revenue-based funding alternatives to equity investment. Facilitating compliant digital equity trading for accredited investors. Delivering blockchain solutions for modern corporate finance."
  },
  theme: {
    primary: '#22c55e',    // Green
    secondary: '#16a34a',
    accent: '#4ade80',
    background: '#0f172a',
    text: '#f8fafc',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About ChainTokenomics",
    subtitle: "Revolutionizing Corporate Finance",
    mission: {
      title: "Our Mission",
      content: "To transform corporate finance through innovative blockchain solutions and DeFi mechanisms."
    },
    team: [
      {
        name: "DRT Platform Team",
        role: "Product Development",
        bio: "Experts in deferred revenue tokenization and smart contracts"
      },
      {
        name: "ShareTwin Team",
        role: "Equity Trading Solutions",
        bio: "Specialists in compliant digital equity trading platforms"
      },
      {
        name: "Compliance Team",
        role: "Regulatory Oversight",
        bio: "Ensuring full regulatory compliance in all solutions"
      }
    ],
    values: [
      {
        title: "Financial Innovation",
        description: "Creating new paradigms in blockchain finance"
      },
      {
        title: "Regulatory Compliance",
        description: "Maintaining highest standards of regulatory compliance"
      },
      {
        title: "Technological Excellence",
        description: "Delivering robust blockchain solutions"
      }
    ]
  },
  portfolioPage: {
    title: "Our Products",
    subtitle: "Revolutionary DeFi Solutions",
    companies: [
      {
        name: "Deferred Revenue Token Platform",
        description: "Blockchain-based platform for tokenizing and trading future revenue commitments",
        status: "Active",
        year: 2024
      },
      {
        name: "ShareTwin",
        description: "Compliant blockchain platform for private equity trading",
        status: "Active",
        year: 2024
      }
    ],
    investmentCriteria: [
      {
        title: "Innovation",
        description: "Novel approaches to blockchain finance"
      },
      {
        title: "Compliance",
        description: "Strong regulatory framework"
      },
      {
        title: "Market Impact",
        description: "Significant market opportunity"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Blockchain Finance Innovation",
    markdown: `
# Technology Solutions

## DRT Platform
- Smart contract-based revenue commitments
- Automated revenue distribution
- Real-time tracking and reporting
- Transparent audit trails

## ShareTwin Platform
- Integrated AML/KYC verification
- Accredited investor certification
- Smart contract-based trading
- Regulatory compliance automation

## Implementation Areas

### Revenue Tokenization
- Future revenue commitments
- Automated distributions
- Performance tracking
- Compliance monitoring

### Digital Equity Trading
- Private equity transactions
- Regulatory compliance
- Market liquidity
- Transaction efficiency

Contact us to learn more about our blockchain finance solutions.
`
  },
  homePage: {
    hero: {
      title: "Future of Corporate Finance",
      highlight: "Blockchain Innovation",
      description: "Revolutionizing corporate finance through tokenized revenue and compliant digital equity trading"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
