import type { SiteConfig } from "../sites.ts";

export const zeroServerConfig: SiteConfig = {
  company: {
    name: "ZeroServer.org",
    tagline: "Edge Computing Excellence",
    description: "Specialized consulting in modern edge computing technologies and distributed systems",
    logo: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[var(--color-primary)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
      </svg>`
    }
  },
  theme: {
    primary: '#0891b2',    // Cyan
    secondary: '#0e7490',
    accent: '#06b6d4',
    background: '#f8fafc',
    text: '#0f172a',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About ZeroServer.org",
    subtitle: "Pioneering Edge Computing Solutions",
    mission: {
      title: "Our Mission",
      content: "To revolutionize edge computing deployment and management through innovative solutions and expert consulting."
    },
    team: [
      {
        name: "Dr. Sarah Chen",
        role: "Principal Architect",
        bio: "PhD in Distributed Systems, 15+ years experience in edge computing"
      },
      {
        name: "Marcus Rodriguez",
        role: "Head of WASM Solutions",
        bio: "WebAssembly expert, contributor to major WASM projects"
      },
      {
        name: "Aisha Patel",
        role: "Network Architecture Lead",
        bio: "SD-WAN specialist, former Cisco technical leader"
      }
    ],
    values: [
      {
        title: "Innovation",
        description: "Pushing the boundaries of edge computing"
      },
      {
        title: "Efficiency",
        description: "Optimizing for performance and resource usage"
      },
      {
        title: "Security",
        description: "Zero-trust architecture by default"
      }
    ]
  },
  portfolioPage: {
    title: "Our Solutions",
    subtitle: "Cutting-edge Computing Solutions",
    companies: [
      {
        name: "Edge Deployment Suite",
        description: "K3s and WASM-based edge computing platform",
        status: "Production",
        year: 2023
      },
      {
        name: "ZeroNet",
        description: "Advanced SD-WAN implementation",
        status: "Beta",
        year: 2024
      },
      {
        name: "EdgeScale",
        description: "Automated edge infrastructure scaling",
        status: "Production",
        year: 2023
      }
    ],
    investmentCriteria: [
      {
        title: "Performance",
        description: "Optimized for edge computing environments"
      },
      {
        title: "Scalability",
        description: "Designed for distributed deployment"
      },
      {
        title: "Security",
        description: "Zero-trust security model"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology Stack",
    subtitle: "Modern Edge Computing Technologies",
    markdown: `
# Edge Computing Technologies

## Core Technologies

### WebAssembly (WASM)
- Browser-based computation
- Native-speed performance
- Cross-platform compatibility
- Secure sandboxed execution

### Deno Runtime
- Modern JavaScript/TypeScript runtime
- Built-in security features
- Native WebAssembly support
- Enhanced developer experience

### K3s Lightweight Kubernetes
- Edge-optimized container orchestration
- Minimal resource footprint
- Production-grade Kubernetes
- Simplified deployment model

### SD-WAN Solutions
- Software-defined networking
- Intelligent traffic routing
- Zero-trust security model
- Automated failover

## Implementation Expertise

### Edge Computing Architecture
- Distributed systems design
- Edge-native applications
- Microservices optimization
- Real-time processing

### Performance Optimization
- WASM module optimization
- Network latency reduction
- Resource utilization
- Cache strategy design

Contact us to learn how we can enhance your edge computing infrastructure.
`
  },
  solutionsPage: {
    title: "Our Services",
    subtitle: "Expert Edge Computing Solutions",
    markdown: `
# Consulting Services

## Edge Computing Solutions

### WASM Implementation
- Application optimization
- Browser-based computing
- Performance tuning
- Security hardening

### Deno Deployment
- Modern runtime adoption
- TypeScript optimization
- Security configuration
- Production deployment

### K3s Architecture
- Cluster design
- Edge orchestration
- Resource optimization
- High availability setup

### SD-WAN Design
- Network architecture
- Traffic optimization
- Security implementation
- Failover design

## Training & Support

### Technology Adoption
- Team training
- Best practices
- Architecture review
- Performance optimization

### Ongoing Support
- 24/7 monitoring
- Incident response
- Performance tuning
- Security updates

Contact us to discuss your edge computing needs.
`
  },
  homePage: {
    hero: {
      title: "Edge Computing Excellence",
      highlight: "Modern Edge Solutions",
      description: "Expert consulting in WASM, Deno, K3s, and SD-WAN technologies"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
