import type { SiteConfig } from "../sites.ts";

export const globalDevOpsConfig: SiteConfig = {
  company: {
    name: "GlobalDevOps.io",
    tagline: "Virtual CTO Services Powered by AI",
    description: "Global technology advisory and implementation services leveraging AI agents for enhanced productivity",
    logo: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[var(--color-primary)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
      </svg>`
    }
  },
  theme: {
    primary: '#6366f1',    // Indigo
    secondary: '#4f46e5',
    accent: '#818cf8',
    background: '#f8fafc',
    text: '#1e293b',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About GlobalDevOps.io",
    subtitle: "Your Global Virtual CTO Partner",
    mission: {
      title: "Our Mission",
      content: "To deliver enterprise-grade technology solutions through AI-enhanced global teams."
    },
    team: [
      {
        name: "Global Leadership",
        role: "24/7 Coverage",
        bio: "Strategic teams across North America, Latin America, UK/Europe, and Asia"
      },
      {
        name: "AI Integration Team",
        role: "AI/ML Specialists",
        bio: "Expert implementation of AI agents for business process automation"
      },
      {
        name: "DevOps Engineers",
        role: "Global Operations",
        bio: "Follow-the-sun DevOps coverage and support"
      }
    ],
    values: [
      {
        title: "Global Excellence",
        description: "24/7 operations across all major time zones"
      },
      {
        title: "AI Innovation",
        description: "Leveraging AI agents for enhanced productivity"
      },
      {
        title: "Cost Efficiency",
        description: "Optimized global resource allocation"
      }
    ]
  },
  portfolioPage: {
    title: "Our Services",
    subtitle: "Comprehensive Technology Solutions",
    companies: [
      {
        name: "Strategic Advisory",
        description: "Virtual CTO services and technology roadmapping",
        status: "Available",
        year: 2024
      },
      {
        name: "AI Implementation",
        description: "Enterprise AI agent integration",
        status: "Available",
        year: 2024
      },
      {
        name: "Global DevOps",
        description: "24/7 development and operations",
        status: "Available",
        year: 2024
      }
    ],
    investmentCriteria: [
      {
        title: "Technology Strategy",
        description: "Long-term technology planning"
      },
      {
        title: "AI Integration",
        description: "AI-first approach to solutions"
      },
      {
        title: "Global Delivery",
        description: "Follow-the-sun implementation"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology Stack",
    subtitle: "AI-Enhanced Global Delivery",
    markdown: `
# Technology Services

## Strategic Advisory
- Technology Roadmapping
- Architecture Design
- AI Strategy Development
- Cost Optimization Planning

## AI Implementation
- Custom AI Agent Development
- Process Automation
- Intelligent Workflows
- AI Integration Services

## Global Operations
- 24/7 DevOps Coverage
- Follow-the-sun Development
- Continuous Integration/Deployment
- Global Resource Management

## Service Areas

### Architecture & Design
- System Architecture
- Cloud Infrastructure
- AI/ML Pipeline Design
- Security Architecture

### Development & Implementation
- Global Development Teams
- AI-Enhanced Productivity
- Quality Assurance
- Performance Optimization

### Operations & Support
- 24/7 Global Coverage
- Incident Management
- Performance Monitoring
- Continuous Improvement

Contact us to learn how we can transform your technology operations.
`
  },
  solutionsPage: {
    title: "Our Solutions",
    subtitle: "Global Technology Excellence",
    markdown: `
# Service Solutions

## Virtual CTO Services
- Technology Strategy
- Architecture Review
- Vendor Selection
- Cost Optimization

## AI Integration
- AI Agent Development
- Process Automation
- Workflow Optimization
- ROI Analysis

## Global DevOps
- 24/7 Operations
- Global Team Management
- Resource Optimization
- Quality Assurance

## Implementation Areas

### Strategic Planning
- Technology Roadmap
- AI Strategy
- Resource Planning
- Budget Optimization

### Global Delivery
- Follow-the-sun Development
- Quality Management
- Risk Mitigation
- Performance Monitoring

### Continuous Support
- 24/7 Coverage
- Incident Response
- System Monitoring
- Performance Tuning

Contact us to discuss your technology needs.
`
  },
  homePage: {
    hero: {
      title: "Global Technology Excellence",
      highlight: "AI-Enhanced Delivery",
      description: "Strategic technology services with 24/7 global coverage and AI-powered efficiency"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
