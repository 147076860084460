import type { SiteConfig } from "../sites.ts";

const siteConfig: SiteConfig = {
  company: {
    name: "MDAM Group",
    tagline: "Canada's premier AI-Agent venture studio, combining strategic advisory, operational expertise, and investment in breakthrough technologies.",
    description: "First venture studio specializing in AI-Agent company creation and scaling. Led by elite technology operators and strategic advisors. Focused on AI, Ethereum blockchain, and edge computing innovations. Accelerating Canadian startup growth through hands-on venture building.",
    logo: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[var(--color-primary)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
      </svg>`
    }
  },
  theme: {
    primary: '#0ea5e9',    // Sky Blue
    secondary: '#0284c7',
    accent: '#38bdf8',
    background: '#f8fafc',
    text: '#0f172a',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About MDAM Group",
    subtitle: "Premier AI-Agent Venture Studio",
    mission: {
      title: "Our Mission",
      content: "To build and scale breakthrough AI-Agent companies that define the future of technology."
    },
    team: [
      {
        name: "Strategic Advisory",
        role: "Leadership Team",
        bio: "Elite technology operators and strategic advisors"
      },
      {
        name: "Technical Excellence",
        role: "Technology Team",
        bio: "Experts in AI, blockchain, and edge computing"
      },
      {
        name: "Operational Support",
        role: "Operations Team",
        bio: "Experienced in scaling technology ventures"
      }
    ],
    values: [
      {
        title: "Technical Excellence",
        description: "Leading edge in AI and blockchain technology"
      },
      {
        title: "Innovation Leadership",
        description: "Pioneering new technological frontiers"
      },
      {
        title: "Canadian Growth",
        description: "Accelerating Canadian technology success"
      }
    ]
  },
  portfolioPage: {
    title: "Our Ventures",
    subtitle: "Building Tomorrow's Technology Leaders",
    companies: [
      {
        name: "Venture Studio",
        description: "Comprehensive venture building platform",
        status: "Active",
        year: 2024
      },
      {
        name: "Strategic Advisory",
        description: "Expert technology guidance",
        status: "Active",
        year: 2024
      }
    ],
    investmentCriteria: [
      {
        title: "Innovation",
        description: "Breakthrough technology potential"
      },
      {
        title: "Execution",
        description: "Strong operational capabilities"
      },
      {
        title: "Impact",
        description: "Significant market opportunity"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Advanced Technology Domains",
    markdown: `
# Core Competencies

## AI and Machine Learning
- Large Language Models (LLMs)
- Autonomous Agent Systems
- Neural Network Architecture
- Machine Learning Operations
- AI Model Optimization
- Natural Language Processing

## Blockchain Technology
- Ethereum Smart Contracts
- DeFi Protocol Development
- Token Economics
- Consensus Mechanisms
- Layer 2 Solutions
- Web3 Infrastructure

## Edge Computing
- Distributed Systems
- Mesh Networks
- Edge AI Implementation
- Low-latency Computing
- Resource Optimization
- Edge Security

# Development Stack

## Frontend
- React/Next.js
- TypeScript
- WebAssembly
- Progressive Web Apps

## Backend
- Rust
- Go
- Node.js
- Python

## Infrastructure
- Kubernetes
- Docker
- AWS/Azure/GCP
- Edge Computing Platforms

## Blockchain
- Solidity
- Web3.js
- Ethereum Tools
- IPFS

# Innovation Areas

## Autonomous Systems
- AI Agent Architecture
- Multi-agent Systems
- Agent Orchestration
- Behavioral Programming

## Decentralized Computing
- P2P Networks
- Distributed Storage
- Edge Processing
- Mesh Networking

## Enterprise AI
- LLM Integration
- Custom AI Models
- Process Automation
- Decision Systems

Contact us to explore venture opportunities.
`
  },
  solutionsPage: {
    title: "Our Services",
    subtitle: "Comprehensive Venture Building",
    markdown: `
# Venture Studio Services

## Company Creation
- Idea validation
- Team building
- Technology strategy
- Market positioning

## Strategic Advisory
- Technology roadmap
- AI implementation
- Blockchain architecture
- Edge computing strategy

## Operational Support
- Technical expertise
- Development resources
- Infrastructure setup
- Scaling guidance

## Investment
- Direct funding
- Capital strategy
- Investor network
- Growth planning

Contact us to discuss your venture.
`
  },
  homePage: {
    hero: {
      title: "Building AI-Agent Companies",
      highlight: "Future of Technology",
      description: "Premier venture studio for breakthrough AI and blockchain innovations"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};

export default siteConfig;
